<template>
  <div class="modal-card modal-data rounded-10">
    <section class="modal-card-body">
      <div class="content pb-30">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('data_agency') }}</div>
          <button @click="close" class="delete" aria-label="close"></button>
        </div>
        <section class="data">
          <div class="columns mt-20">
            <div class="column is-4">
              <div class="is-flex">
                <div class="is-flex is-flex-direction-column">
                  <span class="label-data">{{ $t('name') }}</span>
                  <span class="label-data">{{ $t('email') }}</span>
                  <span class="label-data">{{ $t('phone') }}</span>
                </div>
                <div class="is-flex is-flex-direction-column ml-40">
                  <span class="item name">{{ item.name }}</span>
                  <span class="item email">{{ item.email }}</span>
                  <span class="item phone">{{ item.phoneNumber }}</span>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="is-flex">
                <div class="is-flex is-flex-direction-column">
                  <span class="label-data">{{ $t('nro_agents') }}</span>
                  <span class="label-data" v-if="item.paymentGroups.length">{{ $t('payment_groups') }}</span>
                  <span class="label-data">{{ $t('active') }}</span>
                </div>
                <div class="is-flex is-flex-direction-column ml-40">
                  <span class="item agents">{{ item.totalAgents }}</span>
                  <span class="item payment-groups" v-for="(value, idx) in item.paymentGroups"
                        :key="idx">
                    <li>{{ value.currencyTo }}:{{ value.amount }}</li>
                  </span>
                  <span v-if="item.isActive" class="item active">{{ $t('yes') }}</span>
                  <span v-else class="item active">{{ $t('no') }}</span>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="is-flex">
                <div class="is-flex is-flex-direction-column ml-40">
                  <span class="label-data">{{ $t('created') }}</span>
                  <span class="label-data">{{ $t('updated') }}</span>
                </div>
                <div class="is-flex is-flex-direction-column ml-40">
                  <span class="item">{{ item.created }}</span>
                  <span class="item">{{ item.updated }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="columns mt-20">
            <div class="column is-half">
              <div class="is-flex">
                <div class="is-flex is-flex-direction-column">
                  <span class="label-data">{{ $t('address') }}</span>
                  <span class="label-data">{{ $t('zip') }}</span>
                </div>
                <div class="is-flex is-flex-direction-column ml-40">
                  <span class="item address">{{ item.addressLine }}</span>
                  <span class="item address">{{ item.zip }}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="is-flex mt-20 is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('history_cash_agency') }}</div>
        </div>
        <section class="mt-20 rounded-10 px-30">
          <div class="columns is-flex head is-align-items-center pl-30">
            <div class="column is-half">{{ $t('date_cash') }}</div>
            <div class="column is-3">{{ $t('amount_cup') }}</div>
            <div class="column is-3">{{ $t('amount_usd') }}</div>
          </div>
          <div class="columns pl-30" v-for="(val, idx) in balances.data" :key="idx">
            <div class="column is-flex is-half">
              <div>{{ formatYYYYMMDD(val.date) }}</div>
              <!--              <div class="mx-40">{{ val.hour }}</div>-->
            </div>
            <div class="column is-3">{{ val.balanceCup }}</div>
            <div class="column is-3">{{ val.balanceUSD }}</div>
          </div>
        </section>
        <paginate
            :count="balances.totalRecords"
            :per_page="balances.itemsPerPage"
            :current-page="balances.currentPage"
            :total-pages="balances.totalPages"
            @first="getLast(true)"
            @previous="getBalance(balances.currentPage - 1,balances.itemsPerPage)"
            @next="getBalance(balances.currentPage + 1,balances.itemsPerPage)"
            @last="getLast"
            @perPage="getBalance(balances.currentPage,$event)"
            @search="getBalance($event,balances.itemsPerPage)"

        />
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import Paginate from "@/components/list/paginate";

export default {
  name: "modalDataAgency",
  components: {Paginate},
  props: ['item'],
  data() {
    return {
      balances: []
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'SET_LOADING'
    }),
    ...mapActions({
      fetchBalancesAssignment: 'FETCH_AGENCY_BALANCE_ASSIGNMENTS'
    }),
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    close() {
      this.setLoading()
      this.$emit('close')
      this.balances = []
      this.$emit('reload')
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getBalance(page, this.desserts.itemsPerPage)
    },
    getBalance(current, record, id = this.item.id) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchBalancesAssignment(data)
            .then(value => {
              resolve(value.result)
            })
      })
    }
  },
  created() {
    this.getBalance(1,10)
        .then(value => {
          this.balances = value
        })
  }
}
</script>

<style scoped lang="scss">
.data {
  padding: 0 70px;
}

.item {
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.5px;

  &.name {
    color: #666BAA;
    font-weight: bold;
  }

  &.email {
    color: #0B2B46;
  }

  &.phone {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
  }

  &.agents, &.active {
    font-size: 18px;
    color: #000000;
    font-weight: 900;
  }

  &.payment-groups {
    color: #289BA4;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
  }

  &.address {
    color: #0B2B46;
    font-weight: normal;
  }
}

.label-data {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;
}

.head {
  background: #F0EFEF;
  height: 69px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;
}
</style>