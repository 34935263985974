<template>
  <section>
    <breadcrumb :title="$t('agencies')" :subtitle="$t('list_agency')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       v-model="search"
                       type="search"
                       icon="magnify"
                       icon-clickable
                       @icon-click="searchIconClick"
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div @click="filter=!filter" class="ml-25">
            <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
          </div>
        </div>
        <div class="mr-20" @click="goTo('formAgency')">
          <img class="filter" :src="addAgency">
        </div>
        <div class="mr-20" @click="generateReport">
          <b-icon type="is-purple" size="is-medium" icon="download"></b-icon>
        </div>
      </div>
      <template v-if="filter">
        <div class="columns">
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('name')"
                         v-model="query.name"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('agents')"
                         v-model="query.agent"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('email')"
                         v-model="query.email"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('payment_groups')"
                         v-model="query.pg"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('phone')"
                         v-model="query.phone"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('state')"
                         v-model="query.state"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-flex-end">
              <b-button @click="getQueryFilter" class="btn btn-search" type="is-primary">
                {{ $t('search') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts.data"
          ref="table"
          :paginated="false"
          :per-page="desserts.itemsPerPage"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column field="name" :label="$t('name')" v-slot="props">
            <span @click="setPropsModals(props.row)" class="col name cursor-pointer">
              {{ props.row.name }}
            </span>
        </b-table-column>

        <b-table-column field="email" :label="$t('email')" v-slot="props">
       <span class="email">
         {{ props.row.email }}
       </span>
        </b-table-column>

        <b-table-column field="phone" :label="$t('phone')" v-slot="props">
        <span class="col common">
           {{ props.row.phoneNumber }}
        </span>
        </b-table-column>

        <b-table-column field="phone" :label="$t('exchange_rate')" v-slot="props">
          <div @click="setPropsModalsExchangeRate(props.row)" class="cursor-pointer">
            <b-tooltip :label="$t('edit')">
              <b-tag type="is-green-success" rounded>
                <span class="text-status is-uppercase"> 1x{{ props.row.exchangeRate }}</span>
              </b-tag>
            </b-tooltip>
          </div>
        </b-table-column>

        <b-table-column field="active" :label="$t('active')" v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
          </div>
        </b-table-column>
        <b-table-column field="agents" :label="$t('agents')" v-slot="props">
        <span class="col common">
          {{ props.row.totalAgents }}
        </span>
        </b-table-column>
        <b-table-column field="payment_groups" :label="$t('utility_agents')" v-slot="props">
          <div v-if="props.row.paymentGroups && props.row.paymentGroups.length">
                <span class="col payment-groups" v-for="(item, idx) in props.row.paymentGroups"
                      :key="idx"
                >
          <li>{{ item.currencyTo }} -( <span
              :class="{'color-red': item.managerCommission.rate < 0}">{{ item.managerCommission.rate }}%</span>)</li>
        </span>
          </div>
          <div v-else>-</div>
        </b-table-column>


        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <router-link :to="{name:'formAgentByAgency', params: {agencyId:props.row.id}}">
              <div class="mx-10">
                <b-icon type="is-success" icon="account-plus" class="mx-4 cursor-pointer"></b-icon>
              </div>
            </router-link>
            <router-link :to="{name:'formEditAgency', params: {id: props.row.id}}">
              <div class="mx-10">
                <b-icon type="is-green-accent" icon="pencil"
                        class="mx-4 cursor-pointer"></b-icon>
              </div>
            </router-link>
            <div class="mx-10" @click="setPropsModals(props.row)">
              <b-icon type="is-primary" icon="launch" custom-class="mdi-rotate-90 cursor-pointer" class="mx-4"></b-icon>
            </div>
            <div class="mx-10" @click="confirmCustomDelete(props.row.id)">
              <b-icon type="is-danger" icon="trash-can-outline" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>

        </b-table-column>
      </b-table>
      <paginate
          :count="desserts.totalRecords"
          :per_page="desserts.itemsPerPage"
          :current-page="desserts.currentPage"
          :total-pages="desserts.totalPages"
          @first="getLast(true)"
          @previous="getAgencies(desserts.currentPage - 1,desserts.itemsPerPage)"
          @next="getAgencies(desserts.currentPage + 1,desserts.itemsPerPage)"
          @last="getLast"
          @perPage="getAgencies(desserts.currentPage,$event)"
          @search="getAgencies($event,desserts.itemsPerPage)"

      />
      <vue-html2pdf v-if="agencyList.length"
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="true"
                    :paginate-elements-by-height="1400"
                    filename="Agency List Report"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="700px"
                    @progress="onProgress($event)"
                    @hasStartedGeneration="hasStartedGeneration()"
                    @hasGenerated="hasGenerated($event)"
                    ref="html2Pdf"
      >
        <section slot="pdf-content">
          <section class="is-flex is-justify-content-space-between is-align-items-center pl-40 mt-30">
            <div class="report-log is-flex is-align-items-center">
              <div><img style=" width: 60px" :src="logo" alt=""></div>
              <div class="is-bold ml-4">{{ $t('list_agency') }}</div>
            </div>
          </section>

          <section class="content-report pl-40 mt-20">
            <div class="columns" style="border-bottom: 1px solid #000000 !important;">
              <div class="column col report head">
                {{ $t('name') }}
              </div>

              <div class="column col report head">
                {{ $t('phone') }}
              </div>
              <div class="column col report head">
                {{ $t('exchange_rate') }}
              </div>
              <div class="column col report head">
                {{ $t('active') }}
              </div>
              <div class="column col report head">
                {{ $t('agents') }}
              </div>
            </div>
            <div class="columns row-report" v-for="(item, idx) in agencyList" :key="idx">
              <div class="column col report">
                {{ item.name }}
              </div>
              <div class="column col report">
                {{ item.phoneNumber }}
              </div>
              <div class="column col report">
                {{ item.exchangeRate }}
              </div>
              <div class="column col report">
                <span v-if="item.isActive"> {{ $t('yes') }} </span>
                <span v-else> {{ $t('yes') }} </span>
              </div>
              <div class="column col report">
                {{ item.totalAgents }}
              </div>
            </div>
          </section>

        </section>
      </vue-html2pdf>
      <b-modal
          v-model="isModalDataAgencyActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modal-data-agency :item="propsModals" @reload="getAgencies(desserts.currentPage,desserts.itemsPerPage)"
                             v-bind="propsModals"
                             @close="props.close"></modal-data-agency>
        </template>
      </b-modal>
      <b-modal
          v-model="isModalDataAgencyEditActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modal-edit-agency :item="propsModalsEdit" v-bind="propsModalsEdit" @close="props.close"></modal-edit-agency>
        </template>
      </b-modal>
      <b-modal
          v-model="isModalDataAgencyEditExchangeRateActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modals-edit-exchange-rate @reload="getAgencies(desserts.currentPage,desserts.itemsPerPage)"
                                     :item="propsModalsExchangeRate" v-bind="propsModalsExchangeRate"
                                     @close="props.close"></modals-edit-exchange-rate>
        </template>
      </b-modal>
    </section>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import addAgency from '@/assets/img/add-invoice.png'
import Paginate from "@/components/list/paginate";
import ModalDataAgency from "@/components/modals/modalDataAgency";
import ModalEditAgency from "@/components/modals/modalEditAgency";
import {mapActions} from "vuex";
import {CURRENCY} from "@/enum/currency";
import ModalsEditExchangeRate from "@/components/modals/modalsEditExcahngeRate";
import VueHtml2pdf from 'vue-html2pdf'
import logo from "@/assets/remisario/fullcolor/imagotipo.jpg";
import {notificationSuccess} from "@/utils";

export default {
  name: "ListAgencyComponent",
  components: {ModalsEditExchangeRate, ModalEditAgency, ModalDataAgency, Paginate, Breadcrumb, VueHtml2pdf},
  data() {
    return {
      logo,
      search: null,
      addAgency,
      agencyList: [],
      filter_name: 'filter-outline',
      filter: false,
      isModalDataAgencyActive: false,
      isModalDataAgencyEditActive: false,
      isModalDataAgencyEditExchangeRateActive: false,
      propsModals: {},
      propsModalsEdit: {},
      propsModalsExchangeRate: {},
      itemsPerPage: 0,
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
      desserts: [],
      query: {
        name: null,
        email: null,
        phone: null,
        state: null,
        pg: null,
        agent: null,
        agentType: null
      }
    }
  },
  methods: {

    ...mapActions({
      fetchAgencies: 'FETCH_AGENCY',
      goTo: 'GO_TO',
      deleteUser: 'DELETE_USER',
    }),
    setPropsModals(newProp) {
      this.propsModals = newProp
      this.isModalDataAgencyActive = true
    },
    setPropsModalsExchangeRate(newProp) {
      this.propsModalsExchangeRate = newProp
      this.isModalDataAgencyEditExchangeRateActive = true
    },
    setPropsModalsEdit(newProp) {
      this.propsModalsEdit = newProp
      this.isModalDataAgencyEditActive = true
    },
    onProgress(event) {
      console.log(event);
    },
    hasGenerated(event) {
      console.log(event);
    },
    hasStartedGeneration() {
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    reload() {
      window.location.reload()
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getAgencies(page, this.desserts.itemsPerPage)
    },
    getAgencies(current, record, id = null, query = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        query: query
      };
      this.fetchAgencies(data)
          .then(value => {
            this.desserts = value
          })
    },
    getAmountUsd(list) {
      let temp = list.find(a => a.currencyTo === CURRENCY.USD)
      if (temp) {
        return temp.amount
      }
      return 0
    },
    getAmountCup(list) {
      let temp = list.find(a => a.currencyTo === CURRENCY.CUP)
      if (temp) {
        return temp.amount
      }
      return 0
    },
    getQueryFilter() {
      let query = ''
      let prefix = '&'
      if (this.query.name) {
        query += `${prefix}name=${this.query.name}`
      }
      if (this.query.email) {
        query += `${prefix}email=${this.query.email}`
      }
      if (this.query.phone) {
        query += `${prefix}phonenumber=${this.query.phone}`
      }
      if (this.query.agent) {
        query += `${prefix}agentName=${this.query.agent}`
      }

      if (this.query.state) {
        query += `${prefix}state=${this.query.state}`
      }
      if (this.query.pg) {
        query += `${prefix}paymentGroupName=${this.query.pg}`
      }
      if (query.length) {
        this.getAgencies(1, 10, null, query)
      } else {
        this.getAgencies(1, 10, null, null)
      }
    },
    searchIconClick() {
      let query = this.search ? `&name=${this.search}` : null
      this.getAgencies(1, 10, null, query)

    },
    confirmCustomDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminando Agencia',
        message: 'Esta seguro que desea <b>eliminar</b> la agencia?',
        confirmText: 'Confirmar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteUser(id)
          .then(() => {
            notificationSuccess('Agencia eliminada');
            this.getAgencies(1, 10)
          })
        }
      })
    }
  },
  watch: {
    filter: function () {
      this.filter_name = this.filter ? 'filter-remove-outline' : 'filter-outline'
    },
    search:function () {
      if (!this.search){
        this.getAgencies(1, 10)
      }
    }
  },
  created() {
    this.getAgencies(1, 10)
        .then(value => {
          this.desserts = value
        })
    this.getAgencies(1, 0)
        .then(value => {
          this.agencyList = value.data
        })
  }
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.report {
    font-size: 10px;
  }

  &.head {
    font-weight: bold;
  }

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.email {
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }

  &.common {
    font-weight: 600;
    font-size: 18px;
    color: #0B2B46;
  }

  &.payment-groups {
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    color: #289BA4;
  }


}

</style>
