<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('edit_agency') }}</div>
          <button @click="$emit('close')" class="delete" aria-label="close"></button>
        </div>
        <section>
          <b-steps size="is-large">
            <b-step-item :title="$t('data')" icon="account-key">
              <section class="pt-10 pb-20">
                <b-field :label="`${$t('name')}*`" custom-class="custom-field-label">
                  <b-input custom-class="full-input" type="text" placeholder=""></b-input>
                </b-field>
                <b-field :label="`${$t('phone')}*`" custom-class="custom-field-label">
                  <b-input custom-class="full-input" type="text" placeholder=""></b-input>
                </b-field>
                <b-field :label="`${$t('email')}*`" custom-class="custom-field-label">
                  <b-input custom-class="full-input" type="email" placeholder=""></b-input>
                </b-field>
                <b-field :label="`${$t('payment_groups')}*`" custom-class="custom-field-label">
                  <b-select expanded>
                    <option></option>
                  </b-select>
                </b-field>
                <div class="is-flex is-justify-content-flex-end mt-20">
                  <b-field>
                    <b-switch :value="true"
                              :left-label="true"
                              size="is-medium"
                              type="is-green-success">
                      {{ $t('active') }}
                    </b-switch>
                  </b-field>
                </div>
              </section>
            </b-step-item>
            <b-step-item :title="$t('localization')" icon="account">
              <section class="pt-10 pb-20">
                <div class="columns">
                  <div class="column">
                    <b-field :label="`${$t('address_number', { number: 1 })}*`">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>
                    <b-field :label="$t('address_number', { number: 2 })">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>
                    <b-field :label="$t('address_number', { number: 3 })">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>

                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="columns">
                      <div class="column is-half">
                        <b-field :label="`${$t('city')}*`" custom-class="custom-field-label">
                          <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                        </b-field>
                        <b-field :label="`${$t('zip')}*`" custom-class="custom-field-label">
                          <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                        </b-field>
                      </div>
                      <div class="column is-half">
                        <b-field :label="`${$t('state')}*`" custom-class="custom-field-label">
                          <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                        </b-field>
                        <b-field :label="`${$t('country')}*`" custom-class="custom-field-label">
                          <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </b-step-item>
            <b-step-item :title="$t('credentials')" icon="account-plus">
              <section class="pt-10 pb-20">
                <div class="columns">
                  <div class="column">
                    <b-field :label="`${$t('username')}*`" custom-class="custom-field-label">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>
                    <b-field :label="`${$t('password')}*`" custom-class="custom-field-label">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>
                  </div>
                </div>
              </section>
            </b-step-item>
          </b-steps>

        </section>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "modalEditAgency",
  props: ['item']
}
</script>

<style scoped lang="scss">

</style>